import { Popconfirm, message } from "antd";
import {
  dateType,
  linkType,
  stringType,
  tagType,
} from "../../../utilities/utilities";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import {
  calculatePercentage,
  toHHMMSS,
} from "../installation/summaryCards/WiresColumns";
import { StopTwoTone, CloseOutlined } from "@ant-design/icons";
import { DisableWire } from "../../../api/services/wiresService";

export const wiresListColumns: ResponsiveTableColumnType[] = [
  {
    label: "Nome",
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return <ResponsiveTableCell type={stringType} value={value} />;
      },
    },
  },
  {
    label: "Descrizione",
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return <ResponsiveTableCell type={stringType} value={value} />;
      },
    },
  },
  {
    label: "Lunghezza (m)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.length}
        />
      ),
    },
  },
  {
    label: "Diametro (mm)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.diameter}
        />
      ),
    },
  },
  {
    label: "Tipo fune",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.wire_type}
        />
      ),
    },
  },
  {
    label: "Montaggio",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.mount_type}
        />
      ),
    },
  },
  {
    label: "Perle/Metro",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.pearl_meter}
        />
      ),
    },
  },
  {
    label: "Diametro Fune (mm)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.wire_diameter}
        />
      ),
    },
  },
  {
    label: "Data Produzione",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={dateType}
          value={new Date(
            data.tableData[data.rowIndex].wire_info?.production_date
          ).toISOString()}
        />
      ),
    },
  },
  {
    label: "Tempo di Utilizzo (hh:mm)",
    name: "usage_time",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        const percent = calculatePercentage(
          value,
          data.tableData[data.rowIndex].wire_info.max_usage
        );
        return (
          <ResponsiveTableCell
            type={tagType}
            value={toHHMMSS(value)}
            color={percent >= 90 ? "red" : percent >= 75 ? "orange" : "green"}
          />
        );
      },
    },
  },
  {
    label: "Utilizzo Massimo (h)",
    name: "info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={(
            Number(data.tableData[data.rowIndex].wire_info.max_usage) / 3600
          ).toString()}
        />
      ),
    },
  },
];

export const wiresListColumnsWithLink: (
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (linkFunction) => [
  {
    label: "Nome",
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <ResponsiveTableCell
            type={linkType}
            value={value}
            link={() => {
              linkFunction(data.tableData[data.rowIndex].id);
            }}
          />
        );
      },
    },
  },
  {
    label: "Cliente",
    name: "last_customer_name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => {
        return <ResponsiveTableCell type={stringType} value={value} />;
      },
    },
  },
  {
    label: "Lunghezza (m)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.length}
        />
      ),
    },
  },
  {
    label: "Diametro (mm)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.diameter}
        />
      ),
    },
  },
  {
    label: "Tipo fune",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.wire_type}
        />
      ),
    },
  },
  {
    label: "Montaggio",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.mount_type}
        />
      ),
    },
  },
  {
    label: "Perle/Metro",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.pearl_meter}
        />
      ),
    },
  },
  {
    label: "Diametro Fune (mm)",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].wire_info?.wire_diameter}
        />
      ),
    },
  },
  {
    label: "Data Produzione",
    name: "wire_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={dateType}
          value={new Date(
            data.tableData[data.rowIndex].wire_info?.production_date
          ).toISOString()}
        />
      ),
    },
  },
  {
    label: "Tempo di Utilizzo (hh:mm)",
    name: "usage_time",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        const percent = calculatePercentage(
          value,
          data.tableData[data.rowIndex].wire_info.max_usage
        );
        return (
          <ResponsiveTableCell
            type={tagType}
            value={toHHMMSS(value)}
            color={percent >= 90 ? "red" : percent >= 75 ? "orange" : "green"}
          />
        );
      },
    },
  },
  {
    label: "Utilizzo Massimo (h)",
    name: "info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={(
            Number(data.tableData[data.rowIndex].wire_info.max_usage) / 3600
          ).toString()}
        />
      ),
    },
  },
  {
    label: "Dismetti",
    name: "disabled_at",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        if (data.tableData[data.rowIndex].disabled_at) {
          return <CloseOutlined style={{ color: "red" }} />;
        } else {
          return (
            <Popconfirm
              title="Sei sicuro? In questo modo il cavo non sarà più utilizzabile e verrà creato il report di fine vita."
              onConfirm={() => {
                DisableWire(data.tableData[data.rowIndex].id).then((res) => {
                  if (res && !res.err) {
                    message.success("Cavo disabilitato con successo.");
                    window.location.reload();
                  } else {
                    message.error(
                      res?.err?.message ||
                        "Operazione fallita, riprova più tardi."
                    );
                  }
                });
              }}
              onCancel={() => {}}
              okText="Si"
              cancelText="No"
            >
              <StopTwoTone />
            </Popconfirm>
          );
        }
      },
    },
  },
];
